<template>
    <div class="product">
        <el-image :src="getImg(info.logo)" fit="cover"></el-image>
        <div class="content">
            <div class="name">{{ info.name }}</div>
            <div class="sub">
                {{ info.introduction }}
            </div>
            <div class="text">
                <!-- <span>成果所属人：{{ info.owner }}</span> -->
                <span>擅长领域：{{ info.fieldName.join(',') }}</span>

                <span>{{ formatTime(info.createdAt, 'YYYY.MM.DD') }}</span>
            </div>
        </div>
        <div class="edit-btn" v-if="isEdit">
            <el-button type="primary" size="small" plain @click.prevent="edit">编辑</el-button>
            <el-button size="small" plain @click.prevent="del">删除</el-button>
        </div>
        <div class="btn" v-else>
            <el-button type="primary" class="icon-button" @click.prevent="connectKefu" size="small" plain>
                <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                咨询客服
            </el-button>
            <el-button type="primary" @click.prevent="postMessage" class="icon-button" size="small" plain>
                <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
            </el-button>
        </div>
        <!-- <el-button type="primary" size="small" plain>查看详情</el-button> -->
    </div>
</template>

<script>
import comEvent from '../../mixins/comEvent';
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    mixins: [comEvent],
    methods: {
        edit() {
            this.$router.push({
                path: '/serviceProviderEdit',
                query: {
                    id: this.info.id
                }
            });
        },
        del() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/serviceProvider/del/${this.info.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$emit('refreash');
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>

<style lang="less" scoped>
.product {
    .flex();
    padding: 10px 20px;
    border: 1px solid @bg;
    margin: 10px 0;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 3px 12px 0px rgba(255, 135, 0, 0.2);
        border: 1px solid #ff8700;
    }
}
.el-image {
    width: 138px;
    height: 140px;
}
.content {
    flex-grow: 1;
    padding: 14px 0 14px 16px;
}

.name {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    line-height: 22px;
    .ellipsis();
}
.edit-btn {
    .flex();
    .el-button {
        &:first-child {
            width: 88px;
        }

        &:last-child {
            width: 56px;
        }
    }
}
.sub {
    font-size: 14px;
    color: #939599;
    line-height: 40px;
    .ellipsis-line();
    height: 40px;
    padding: 12px 0 18px;
}

.text {
    .flex();
    font-size: 14px;
    color: #939599;
    line-height: 20px;
    span {
        &:nth-child(2) {
            flex-grow: 1;
            padding: 0 35px;
        }
    }
}

.icon-button {
    line-height: 36px;
    display: flex;
}
.icon-button + .icon-button {
    margin-left: 0;
    margin-top: 16px;
}
</style>
